import type { AsVuexStore } from '@zento-lib/components/vuex';

export const KEY = 'notification';

export type IVSFNotification = AsVuexStore<
{
  notifications: any[];
},
'notifications',
'spawnNotification' | 'removeNotification',
'add' | 'remove'
>;
