import { Component, BaseComponent, namespace } from '@zento-lib/components';
import { IVSFNotification, KEY as NotificationKey } from 'theme/@types/vsf/stores/notification';
import { IVSFCompare, KEY as CompareKey } from 'theme/@types/vsf/stores/compare';

import { INotification } from './Notification.d';
import style from './style.scss';

const notificationStore = namespace<IVSFNotification>(NotificationKey);
const compareStore = namespace<IVSFCompare>(CompareKey);

/**
 * Notification
 *
 * Renders helping information about a current action performed by end user
 */
@Component({})
export class Notification extends BaseComponent<INotification, unknown> {
  @notificationStore.State('notifications')
  notifications: IVSFNotification['state']['notifications'];

  @compareStore.Getter('isEmpty')
  isEmpty: boolean;

  beforeMount() {
    this.execAction = this.execAction.bind(this);
  }

  execAction(action: Record<string, any>, index: number) {
    if (action.action) {
      // for backward compatibility
      if (action.action === 'close') {
        this.$store.dispatch('notification/removeNotification', index);
      } else {
        action.action();
      }
    }

    this.$store.dispatch('notification/removeNotification', index);
  }

  render() {
    return (
      <div
        class={{
          [style.notificationsWrapper]: true,
          [style.compareActivated]: !this.isEmpty && this.$route.path !== '/compare',
        }}
        data-testId='notificationsWrapper'>
        <transition-group
          name='fade-in-down'
          enterActiveClass={style.fadeInDownEnterActive}
          leaveActiveClass={style.fadeInDownLeaveActive}>
          {this.notifications.map((notification, index) => {
            return (
              <div key={notification.type + '_' + index} class={style.notificationContainer}>
                <div
                  class={{
                    [style.notification]: true,
                    [style.onlyMessage]: !notification.action2 || !notification.action1,
                    [style.closeBtn]: notification.action1,
                  }}>
                  <div data-testId='notificationMessage' class={style.message}>
                    {notification.message}
                  </div>
                  <div class={style.actions}>
                    {notification.action2 ? (
                      <div class={style.notificationBtnContainer}>
                        <div
                          class={[style.notificationBtn, style.notificationBtnAction]}
                          id='notificationAction2'
                          data-testId='notificationAction2'
                          onClick={() => this.execAction(notification.action2, index)}>
                          {notification.action2.label}
                        </div>
                      </div>
                    ) : null}
                    {notification.action1 ? (
                      <div class={[style.notificationBtnContainer, style.notificationBtnCloseContainer]}>
                        <div
                          class={[style.notificationBtn, style.notificationBtnClose]}
                          id='notificationAction1'
                          data-testId='notificationAction1'
                          onClick={() => this.execAction(notification.action1, index)}>
                          {notification.action1.label}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </transition-group>
      </div>
    );
  }
}
