import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { ConfirmOrders } from '@vue-storefront/core/modules/offline-order/components/ConfirmOrders';
import { CancelOrders } from '@vue-storefront/core/modules/offline-order/components/CancelOrders';

import { Modal } from '../../Modal/Modal';
import { OrderConfirmationButtons } from '../../../atom/OrderConfirmationButtons/OrderConfirmationButtons';
import { CartProduct } from '../../../molecule/Cart/Product/Product';

import { IOrderConfirmation } from './OrderConfirmation.d';
import style from './style.scss';

interface IOrderConfirmationVSF {
  cancelOrders(): void;
  confirmOrders(): void;
}

/**
 * Order confirmation
 *
 * Order confirmation allowing to show confirmation order details.
 **/
@Component({
  mixins: [ConfirmOrders, CancelOrders],
})
export class OrderConfirmation extends BaseComponent<IOrderConfirmation, IOrderConfirmationVSF> {
  private static T = {
    orderConfirmationTitle: 'order_confirmation_title',
    orderConfirmationContentTitle: 'order_confirmation_content_title',
    orderConfirmationOrder: 'order_confirmation_order',
    orderConfirmationOrderItems: 'order_confirmation_order_items',
    orderConfirmationOrderName: 'order_confirmation_order_name',
    orderConfirmationOrderPrice: 'order_confirmation_order_price',
    orderConfirmationOrderQty: 'order_confirmation_order_qty',
    orderConfirmationOrderTotal: 'order_confirmation_order_total',
    orderConfirmationCancel: 'order_confirmation_cancel',
    orderConfirmationBtn: 'order_confirmation_btn',
  };

  @Prop({ type: Array, required: false, default: () => [] })
  ordersData?: any[];

  /**
   * Determines active sections
   */
  isActivesection() {
    return true;
  }

  mounted() {
    this.$nextTick(() => {
      this.broadcast('modal-show', 'modal-order-confirmation');
    });
  }

  confirmOrders() {
    ConfirmOrders.methods.confirmOrders.call(this);
    this.broadcast('modal-hide', 'modal-order-confirmation');
  }

  cancelOrders() {
    CancelOrders.methods.cancelOrders.call(this);
    this.broadcast('modal-hide', 'modal-order-confirmation');
  }

  render() {
    return (
      <Modal class={style.orderConfirmation} name='modal-order-confirmation'>
        <div slot='header'>
          <h2>{this.getTranslation({ id: OrderConfirmation.T.orderConfirmationTitle })}</h2>
        </div>
        <div class={style.modalContentOrder} slot='content'>
          <h3>{this.getTranslation({ id: OrderConfirmation.T.orderConfirmationContentTitle })}</h3>
          {this.ordersData.map((order, key) => {
            return (
              <div class={style.orderBox} key={key}>
                <h4>
                  {this.getTranslation({ id: OrderConfirmation.T.orderConfirmationOrder })} {' ' + '#' + key + 1}
                </h4>
                <ul class={style.orderConfirmation}>
                  {order.products.map((product, productKey) => {
                    return <CartProduct key={productKey} product={product} customStyle={true} editSection={false} />;
                  })}
                </ul>
              </div>
            );
          })}

          <OrderConfirmationButtons
            confirmOrderLabel={{ id: OrderConfirmation.T.orderConfirmationBtn }}
            cancelOrderLabel={{ id: OrderConfirmation.T.orderConfirmationCancel }}
            confirmOrder={this.extended.confirmOrders}
            cancelOrder={this.extended.cancelOrders}
            key='order-confirmation-buttons'
          />
        </div>
      </Modal>
    );
  }
}
