import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      productPageRoutes: [
        'product',
        'virtual-product',
        'bundle-product',
        'simple-product',
        'downloadable-product',
        'grouped-product',
        'configurable-product',
      ],
      isProductPage: false,
      isCheckoutPage: false,
    };
  },
  watch: {
    '$route.name': function onRouteChange() {
      this.setCurrentPage();
    },
  },
  computed: {
    ...mapGetters('application-context', ['isServer']),
    canGoBack() {
      return !this.isHistoryEmpty() && this.isProductPage;
    },
  },
  created() {
    this.setCurrentPage();
  },
  methods: {
    setCurrentPage() {
      this.isProductPage = !!this.$route.params.parentSku;
      this.isCheckoutPage = this.$route.name === 'Checkout';
    },
    // Check if history is empty
    isHistoryEmpty() {
      if (!this.isServer) {
        return window.history.length <= 1;
      }

      return false;
    },
  },
};
