import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { Button } from '../../atom/Button/Button';

import type { IOrderConfirmationButtons } from './OrderConfirmationButtons.d';
import style from './style.scss';

/**
 * Order confirmation buttons
 *
 * Order confirmation buttons allowing to confirm order or to close modal.
 **/
@Component({})
export class OrderConfirmationButtons extends BaseComponent<IOrderConfirmationButtons, unknown> {
  /**
   * Set confirm title button
   */
  @Prop({ type: Object, required: true })
  confirmOrderLabel: I18nMessage;

  /**
   * Set cancel title button
   */
  @Prop({ type: Object, required: true })
  cancelOrderLabel: I18nMessage;

  /**
   * Confirm order
   */
  @Prop({ type: Function, default: () => undefined })
  confirmOrder: (ev: Event) => void;

  /**
   * Cancel order
   */
  @Prop({ type: Function, default: () => undefined })
  cancelOrder: (ev: Event) => void;

  render() {
    return (
      <div class={style.buttonContainer}>
        <div class={style.cancelOrder}>
          <Button styleType='tertiary' handler={this.cancelOrder} type='link' data-testId='cancel-order' key='return'>
            {this.getTranslation(this.cancelOrderLabel)}
          </Button>
        </div>
        <div class={style.confirmBtn}>
          <Button
            styleType='primary'
            handler={this.confirmOrder}
            type='button'
            data-testId='confirm-order'
            key='confirm-order'>
            {this.getTranslation(this.confirmOrderLabel)}
          </Button>
        </div>
      </div>
    );
  }
}
