import { BaseComponent, Component, namespace } from '@zento-lib/components';
import { IVSFStoreUI, KEY as UiKey } from 'theme/@types/vsf/stores/ui';

import type { ILoader } from './Loader.d';
import style from './style.scss';

const uiStore = namespace<IVSFStoreUI>(UiKey);

/**
 * Loader
 *
 * Informs the user of loading or computing processes being carried out by the system
 */
@Component({})
export class Loader extends BaseComponent<ILoader, unknown> {
  @uiStore.State('loader')
  isVisible: IVSFStoreUI['state']['loader'];

  data() {
    return {
      message: '',
    };
  }

  beforeMount() {
    this.onBroadcast('notification-progress-start', this.show);
    this.onBroadcast('notification-progress-stop', this.hide);
  }

  beforeDestroy() {
    this.offBroadcast('notification-progress-start', this.show);
    this.offBroadcast('notification-progress-stop', this.hide);
  }

  show(message = null) {
    this.$data.message = message;
    this.$store.commit('ui/setLoader', true);
  }

  hide() {
    this.$store.commit('ui/setLoader', false);
  }

  render() {
    return this.isVisible ? (
      <div class={style.loaderContainer}>
        <div class={style.loaderInnerContainer}>
          <div class={style.spinner}>
            <div class={style.doubleBounce1} />
            <div class={style.doubleBounce2} />
          </div>
          {this.$data.message ? <div class={style.loaderMessageContainer}>{this.$data.message}</div> : null}
        </div>
      </div>
    ) : null;
  }
}
